import $ from 'jquery';
//import jQuery from 'jquery';
//import 'bootstrap/js/dist/util';
//import 'bootstrap/js/dist/dropdown';
import { Tooltip, Toast, Popover } from 'bootstrap';

import './scss/app.scss';

window.$ = $;

$(document).ready(function(){
	console.log('document ready');

	// Navigation levels

	$('.navbar .dropdown').hover(function() {
		$(this).find('.dropdown-menu').first().stop(true, true).slideDown(1);
	}, function() {
		$(this).find('.dropdown-menu').first().stop(true, true).slideUp(1);
	});

	$('.navbar .dropdown > a').click(function(){
		location.href = this.href;
	});


	// Hamburger menu
	const burger = document.querySelector(".burger");

	burger.addEventListener("click", function () {
	  const body = document.body;
	  //body.classList.toggle("nav-open");

	  // if needed to toggle multiple classes
	  const toggleClasses = ["nav-open", "overflow-hidden"];
	  toggleClasses.forEach((toggleClass) => body.classList.toggle(toggleClass));
	});


	// Search icon
	const searchIco = document.querySelector(".search-icon");
	const closeBtn = document.querySelector(".close");

	searchIco.addEventListener("click", function(){
		const body = document.body;
		body.classList.toggle("search-active");
		console.log('search active');
	});

	closeBtn.addEventListener("click", function(){
		const body = document.body;
		body.classList.toggle("search-active");		
	});

	// Partners slider
	function partnersCarousel(){
		jQuery('.partners__slider').owlCarousel({
		    loop:true,
		    margin:10,
		    nav:true,
		    responsive:{
		        0:{
		            items:2
		        },
		        600:{
		            items:2
		        },
		        1000:{
		            items:3
		        }
		    }
		});
	}

	partnersCarousel();


	/*
		This pen cleverly utilizes SVG filters to create a "Morphing Text" effect.
		Essentially, it layers 2 text elements on top of each other, and blurs them depending on which text element should be more visible.
		Once the blurring is applied, both texts are fed through a threshold filter together, which produces the "gooey" effect.
		Check the CSS - Comment the #container rule's filter out to see how the blurring works!
	*/

	

	const elts = {
		text1: document.getElementById("text1"),
		text2: document.getElementById("text2")
	};

	// The strings to morph between. You can change these to anything you want!
	const texts = [
		"  organisations",
		"  products",
		" brands"
	];

	// Controls the speed of morphing.
	const morphTime = 1;
	const cooldownTime = 4;

	let textIndex = texts.length - 1;
	let time = new Date();
	let morph = 0;
	let cooldown = cooldownTime;

	elts.text1.textContent = texts[textIndex % texts.length];
	elts.text2.textContent = texts[(textIndex + 1) % texts.length];

	function doMorph() {
		morph -= cooldown;
		cooldown = 0;
		
		let fraction = morph / morphTime;
		
		if (fraction > 1) {
			cooldown = cooldownTime;
			fraction = 1;
		}
		
		setMorph(fraction);
	}

	// A lot of the magic happens here, this is what applies the blur filter to the text.
	function setMorph(fraction) {
		// fraction = Math.cos(fraction * Math.PI) / -2 + .5;
		
		elts.text2.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
		elts.text2.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;
		
		fraction = 1 - fraction;
		elts.text1.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
		elts.text1.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;
		
		elts.text1.textContent = texts[textIndex % texts.length];
		elts.text2.textContent = texts[(textIndex + 1) % texts.length];
	}

	function doCooldown() {
		morph = 0;
		
		elts.text2.style.filter = "";
		elts.text2.style.opacity = "100%";
		
		elts.text1.style.filter = "";
		elts.text1.style.opacity = "0%";
	}

	// Animation loop, which is called every frame.
	function animate() {
		requestAnimationFrame(animate);
		
		let newTime = new Date();
		let shouldIncrementIndex = cooldown > 0;
		let dt = (newTime - time) / 1000;
		time = newTime;
		
		cooldown -= dt;
		
		if (cooldown <= 0) {
			if (shouldIncrementIndex) {
				textIndex++;
			}
			
			doMorph();
		} else {
			doCooldown();
		}
	}

	// Start the animation.
	animate();
	


	/**
	 * Typwriter effect header
	 **/

	document.addEventListener('DOMContentLoaded',function(event){
	  // array with texts to type in typewriter
	  var dataText = [ "sustainable organisations.", "sustainable products.", "sustainable brands."];
	  
	  // type one text in the typwriter
	  // keeps calling itself until the text is finished
	  function typeWriter(text, i, fnCallback) {
	    // chekc if text isn't finished yet
	    if (i < (text.length)) {
	      // add next character to h1
	     document.querySelector("h2").innerHTML = text.substring(0, i+1) +'<span aria-hidden="true"></span>';

	      // wait for a while and call this function again for next character
	      setTimeout(function() {
	        typeWriter(text, i + 1, fnCallback)
	      }, 100);
	    }
	    // text finished, call callback if there is a callback function
	    else if (typeof fnCallback == 'function') {
	      // call callback after timeout
	      setTimeout(fnCallback, 700);
	    }
	  }
	  // start a typewriter animation for a text in the dataText array
	   function StartTextAnimation(i) {
	     if (typeof dataText[i] == 'undefined'){
	        setTimeout(function() {
	          StartTextAnimation(0);
	        }, 20000);
	     }
	     // check if dataText[i] exists
	    if (i < dataText[i].length) {
	      // text exists! start typewriter animation
	     typeWriter(dataText[i], 0, function(){
	       // after callback (and whole text has been animated), start next text
	       StartTextAnimation(i + 1);
	     });
	    }
	  }
	  // start the text animation
	  StartTextAnimation(0);
	});

});